<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0">{{$t('newNetwork')}}</p>
            <CButton size="sm" color="primary" class="px-4" @click="$store.commit('setNewNetworkUserModal', true)">{{ $t('addNetworkUser') }}</CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            :loading="$store.state.loadingEvaluatorList"
            striped
						column-filter
            :items="$store.state.evaluatorList"
            :fields="fields"
            :items-per-page="20"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
          >
            <template #actions="data">
              <td>
                <!-- <CButton size="sm" color="success" class="mx-1" @click="">
                  <CIcon name="cil-file" size="sm"/>
                </CButton> -->
                <CButton size="sm" color="warning" class="mx-1" @click="editUser(data)">
                  <CIcon name="cil-pencil" size="sm"/>
                </CButton>
                <CButton size="sm" color="danger" class="mx-1" @click="deleteUser(data)">
                  <CIcon name="cil-x-circle" size="sm"/>
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {deleteUser} from '@/helpers/api/users'
export default {
  name: 'NetowrkUsers',
  data () {
    return {
      fields: [
        { key: 'nombre', label: this.$i18n.t('name') },
        { key: 'apellido', label: this.$i18n.t('lastName') },
        { key: 'especialidad', label: this.$i18n.t('specialty') },
        { key: 'telefono', label: this.$i18n.t('phone') },
        { key: 'email', label: this.$i18n.t('email') },
        { key: 'comuna', label: this.$i18n.t('comuna') },
        { key: 'actions', label: this.$i18n.t('actions'), filter: false }
      ],
      activePage: 1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
    '$store.state.userData': {
      immediate: true,
      handler (userData) {
        if(userData && !this.$store.state.evaluatorList){
          this.getNetworkUsers()
        }
      }
    }
  },
  mounted(){
    this.getNetworkUsers()
  },
  methods: {
    getNetworkUsers(){
      this.$store.dispatch('getUsersList', {type: 1})
    },
    editUser(data){
      this.$store.commit('setEditNetworkUserModal', {status: true, userData: data.item})
    },
    async deleteUser(data){
      if(data && data.item && data.item.id_usuario){
        let completed = false
        const id_usuario = data.item.id_usuario
        const deleted = await deleteUser({id_usuario})
        if(deleted.message){
          completed = true
          this.$store.dispatch('getUsersList', {type: 1})
        }
        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
          header: completed ? 'Usuario Eliminado':'Error',
          message: completed ? 'Se ha eliminado el usuario ' + data.item.nombre + ' ' + data.item.apellido:'Hubo un error en la operación, por favor intenta nuevamente.'
        })
      }
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    }
  }
}
</script>
